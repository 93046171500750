














































































































































































































































































































































@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header-wrapper {
  transition: height 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: top 0.4s;
  box-sizing: border-box;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  // padding: 0 240px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #fff;
  user-select: none;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: auto hidden;
  &.header-expanded {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -130px;
      width: 100%;
      height: 130px;
      background-color: #fff;
    }
  }
  .header-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    // min-width: 960px;
    min-width: 900px;
    height: 90px;
    margin: 0 30px;
  }
  .logo-wrap {
    margin-right: 40px;
    .logo {
      width: 254px;
      height: 37px;
    }
  }
  .nav {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex: 1;
    .nav-item {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 60px;
      line-height: 25px;
      color: #333;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      white-space: nowrap;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: -4px;
          transform: translateX(-50%);
          width: 20px;
          height: 2px;
          background-color: #ff6600;
        }
      }
      > span {
        &:hover {
          font-weight: 500;
        }
      }
      a {
        text-decoration: none;
        color: #333;
        &:hover {
          font-weight: 500;
        }
      }
      .icon {
        width: 12px;
        height: 7px;
        margin-left: 4px;
      }
      .sub-nav-list {
        position: absolute;
        z-index: 100;
        top: 60px;
        .sub-nav-item {
          margin-bottom: 17px;
          // &:last-child {
          //   margin-bottom: 0;
          // }
          a {
            line-height: 25px;
            font-size: 16px;
            font-weight: 400;
            color: #666;
            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .notice-wrap {
    display: flex;
    align-items: center;
    height: 20px;
    margin-left: 42px;
    margin-right: 46px;
    overflow: hidden;
    .icon {
      width: 20px;
      height: 16px;
      margin-right: 4px;
    }
    .icon-box {
      display: flex;
      align-items: center;
      margin-right: 4px;
      .horn-icon {
        margin-right: 2px;
        width: 12px;
        height: 16px;
      }
      .voice-icon {
        width: 8px;
        height: 15px;
        animation: blink 0.5s linear 0.2s infinite;
      }
    }
    .notice-swiper {
      height: 20px;
    }
    .swiper-slide {
      max-width: 209px;
      overflow: hidden;
      white-space: nowrap;
      .text {
        display: block;
        text-decoration: none;
        line-height: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        max-width: 209px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .login-btn {
    box-sizing: border-box;
    width: 88px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid #333;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
    color: #333;
    font-family: PingFangSC-Medium, PingFang SC;
    font-size: 14px;
    font-weight: 500;
    white-space: pre;
    cursor: pointer;
    &:hover {
      border-color: #ff6600;
      background-color: #ff6600;
      color: #fff;
    }
  }

  &.header-wrapper-transparent {
    background-color: transparent;
    .nav {
      .nav-item {
        color: #fff;
        &:hover {
          font-weight: 500;
        }
        a {
          color: #fff;
        }
      }
    }
    .notice-wrap {
      .swiper-slide {
        .text {
          color: #fff;
        }
      }
    }
    .login-btn {
      border: 1px solid #fff;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #333;
      }
    }
  }
  &.header-wrapper-dark {
    border-bottom: none;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.02);
    .nav {
      .nav-item {
        color: #fff;
        &:hover {
          font-weight: 500;
        }
        a {
          color: #fff;
        }
        .sub-nav-list {
          .sub-nav-item {
            a {
              opacity: 0.8;
              color: #fff;
            }
          }
        }
      }
    }
    .notice-wrap {
      .swiper-slide {
        .text {
          color: #fff;
        }
      }
    }
    .login-btn {
      border: 1px solid #fff;
      color: #fff;
      &:hover {
        background-color: #fff;
        color: #333;
      }
    }
  }
}
@media screen and (min-width: 1920px) {
  .header-wrapper {
    .header-container {
      width: 1440px;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 1640px) and (max-width: 1920px) {
  .header-wrapper {
    // padding-right: 30px;
    .header-container {
      margin-left: 240px;
      margin-right: 30px;
    }
    .notice-wrap {
      margin-left: 108px;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1640px) {
  .header-wrapper {
    // padding-left: 30px;
    // padding-right: 30px;
    .header-container {
      margin-left: 30px;
      margin-right: 30px;
    }
    .logo-wrap {
      margin-right: 40px;
    }
    .notice-wrap {
      margin-left: 42px;
    }
  }
}
@media screen and(max-width: 1200px) {
  .header-wrapper {
    overflow-x: auto;
    // overflow-y: hidden;
    .header-container {
      // width: 1140px;
      // padding: 0 30px;
      margin-left: 30px;
      margin-right: 30px;
      .nav {
        .nav-item {
          margin-right: 30px;
        }
      }
    }
  }
}
@media screen and(max-width: 960px) {
  .header-wrapper {
    overflow-x: auto;
    // overflow-y: hidden;
    .header-container {
      width: 900px;
      .logo-wrap {
        margin-right: 30px;
      }
      .nav {
        .nav-item {
          margin-right: 20px;
        }
      }
      .notice-wrap {
        margin-left: 30px;
      }
    }
  }
}
