@media screen and (max-width: 1440px) {
  .page {
    zoom: 0.8;
  }
}
h1,
h2,
h3,
h4,
h5,
ul,
ol,
li,
pre {
  padding: 0;
  margin: 0;
}
ul,
ol {
  list-style: none;
}
.page {
  width: 100%;
  font-family: PingFang SC;
}
.page main,
.page .with-container {
  margin: 0 auto;
  width: 1200px;
}
.common-table {
  width: 100%;
  border-radius: 10px;
}
@font-face {
  font-family: DINCond-Light;
  src: url("https://cdn.mockuai.com/tms/DINCond-Light.otf");
}
@font-face {
  font-family: DINCond-Medium;
  src: url("https://cdn.mockuai.com/tms/DINCond-Medium.otf");
}
@font-face {
  font-family: DINPro-Medium;
  src: url(https://cdn.mockuai.com/tms/DINPro-Medium.otf);
}
