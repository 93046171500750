




























































































































































































.page-footer {
  box-sizing: border-box;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: #00092a;
  &.bg-trans {
    background-color: transparent;
  }
  .footer-container {
    margin: 0 auto;
    min-width: 960px;
    color: #fff;
    .content {
      display: flex;
      justify-content: center;
      padding-top: 52px;
      padding-bottom: 46px;
      .consult {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          .title {
            line-height: 28px;
            margin-bottom: 16px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
          .tel {
            line-height: 35px;
            font-size: 30px;
            font-family: DINCond-Bold, DINCond;
            font-weight: bold;
          }
        }
      }
      .info-item {
        display: flex;
        flex-direction: column;
        margin-right: 60px;
        .title {
          margin-bottom: 31px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 28px;
        }
        .text {
          text-decoration: none;
          color: #fff;
          margin-bottom: 33px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          line-height: 20px;
          opacity: 0.8;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            text-decoration: none;
            color: #fff;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .qrcode {
        display: flex;
        .qrcode-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 18px;
          .icon {
            height: 20px;
            object-fit: cover;
          }
          .code {
            width: 104px;
            height: 104px;
            margin-top: 12px;
          }
        }
      }
    }
    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 50px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 10000px;
        height: 1px;
        background-color: #999;
        opacity: 0.3;
      }
      .item {
        font-size: 14px;
        line-height: 30px;
        opacity: 0.8;
        display: flex;
        .police {
          display: flex;
          align-items: center;
        }
        .item_ico {
          width: 20px;
          height: 20px;
          display: block;
          margin-right: 5px;
        }
        span {
          opacity: inherit;
          a {
            color: #fff;
          }
        }
        a {
          margin-right: 15px;
          text-decoration: none;
          color: #fff;
          opacity: inherit;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 1200px) {
  .page-footer {
    overflow: auto;
    .content {
      .consult {
        margin-right: 30px;
      }
      .info-item {
        margin-right: 20px;
      }
    }
  }
}
@media screen and(min-width: 1200px) and(max-width: 1640px) {
  .page-footer {
    .footer-container {
      width: 1440px;
      .content {
        .consult {
          margin-right: 120px;
        }
        .info-item {
          margin-right: 80px;
        }
      }
    }
  }
}
@media screen and (min-width: 1640px) {
  .page-footer {
    .footer-container {
      width: 1440px;
      .content {
        .consult {
          margin-right: 200px;
        }
        .info-item {
          margin-right: 150px;
        }
      }
    }
  }
}
