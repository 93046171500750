@unitVw: 0.052083333333333336vw;
@indexVw: 0.06097560975609756vw;

.pxToVw (@attr, @px, @base:1920) {
  @vw: (@px / @base) * 100;
  @{attr}: ~"@{vw}vw";
}

.el-message-box__wrapper {
  .el-message-box {
    display: block;
    margin: 0 auto ;
    .pxToVw(margin-top,300);
    .pxToVw(width,390);
    .pxToVw(width,202);
  }
}