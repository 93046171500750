

































.container {
  min-height: calc(100vh - 580px);
  overflow-x: hidden;
}

@media screen and (max-width: 1440px) {
  .container {
    min-height: calc(100vh - 460px);
  }
}
